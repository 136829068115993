import axios from 'axios';


const isProduction = process.env.NODE_ENV !== "development";

//Set server URLS
const baseURL = isProduction ? process.env.REACT_APP_API_URL : process.env.REACT_APP_TEST_API_URL
console.log(baseURL)

const instance = axios.create({
    withCredentials: true,
    credentials: "include",
    baseURL,
    validateStatus: function (status) {
        return status >= 200 && status <= 302;
    }
});

instance.interceptors.response.use(async (response) => {
    if (response.data.needAuth) {
        if (window.location.pathname!=='/login')  window.location.href = '/login'
    }
    return response;
}, (error) => {
    if (error) {
        console.log(error)
    }
    return Promise.reject(error);
});
export default instance