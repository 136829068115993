import "./PsalmPage.css";
import {useUserContext} from "../../../context/userContext/context";
import {Link, NavLink} from "react-router-dom";
import {useState} from "react";

const PsalmPage = () => {
    const {user} = useUserContext()
    const [iframeUrl, setIframeUrl] = useState('')
    return <div>
        <div id={'panelList'}>
            <div className={'socBoxBtn'} onClick={()=>setIframeUrl('https://psalm.blogsportivo.xyz/user/render?token=27b3c0f2f3954ff5a10477ec50e44f22&accessToken=t0v435up3R1353NT0k3N123')}>Control Panel</div>
            <div className={'socBoxBtn'} onClick={()=>setIframeUrl('https://psalm.blogsportivo.xyz/static/linksPanel.html?token=27b3c0f2f3954ff5a10477ec50e44f22&accessToken=t0v435up3R1353NT0k3N123')}>Links Panel</div>
            <div className={'socBoxBtn'} onClick={()=>setIframeUrl('https://psalm.blogsportivo.xyz/static/manualPanel.html?token=27b3c0f2f3954ff5a10477ec50e44f22&accessToken=t0v435up3R1353NT0k3N123')}>Manual Panel</div>
            <div className={'socBoxBtn'} onClick={()=>setIframeUrl('')}>Reviews Panel</div>
        </div>

        {iframeUrl && <iframe src={iframeUrl} style={{width:'99vw', height:'80vh'}}/>}
    </div>;
};
export default PsalmPage;
