import "./IndexPage.css";
import {useUserContext} from "../../../context/userContext/context";
import {timeSince} from '../../../utils/utils'
import {Link, NavLink} from "react-router-dom";

const IndexPage = () => {
    const {user} = useUserContext()
    return <div>
        <h1>Welcome to Soc-Box!</h1>
        <h2 style={{color:user?.shouldReSync?"red":"white"}}>Last Token refresh: <u>{timeSince(user?.lastAuth)} ago</u>. It
            is {user?.shouldReSync ? "highly" : "not"} recommended to refresh your token.</h2>
        <h4>Follow these steps to do so:</h4>
        1. Go to <NavLink
        to={'/controlPanel'}>Control Panel</NavLink><br/>
        2. Click the "Link New FB Account"<br/>
        3. Click the "Update Pages List" next to the account you just verified
    </div>;
};
export default IndexPage;
