import {
    LOGOUT_USER_ERROR, SETUP_USER_ERROR, LOGOUT_USER, STORE_USER_DATA, LOGOUT_USER_BEGIN
} from './actions';


const reducer = (state, action) => {
    if ([LOGOUT_USER_BEGIN].indexOf(action.type) !== -1) {
        return {
            ...state,
            userLoading: true
        }
    }
    //Success
    if ([STORE_USER_DATA,LOGOUT_USER].indexOf(action.type) !== -1) {

        return {
            ...state,
            userLoading: false,
            ...action.payload
        }
    }

    //errors
    if ([LOGOUT_USER_ERROR, SETUP_USER_ERROR].indexOf(action.type) !== -1) {
        return {
            ...state,
            userLoading: false,
            user: null,
            errorMessage: action.payload.msg
        }
    }

    throw new Error(`no such action : ${action.type}`);
}

export default reducer;