import {useEffect, useState} from "react";
import {useUserContext} from "../../../context/userContext/context";
import {useAdminContext} from "../../../context/adminContext/context";
import {useGraphContext} from "../../../context/graphContext/context";

const ActivePageActions = ({
                               chatsActive,
                               setChatsActive,
                               fbCommentsActive,
                               setFbCommentsActive,
                               igCommentsActive,
                               setIgCommentsActive,
                               readAllFunc,
                               activePageData,
                               activePage,
                               activeRecipientData,
                               activeRecipient
                           }) => {

    const [showEditTags, setShowEditTags] = useState(false)
    const [showLabels, setShowLabels] = useState(false)
    const [newLabels, setNewLabels] = useState('')

    const {
        graphLoading,
        updatePageLabels,
        removePageLabel
    } = useGraphContext();
    const {user} = useUserContext()
    const {users, updateUser} = useAdminContext()
    const [pageAssignedToUsers, setPageAssignedToUsers] = useState([])
    const [selectedUserToAssign, setSelectedUserToAssign] = useState('')

    useEffect(() => {
        if (users.length && user?.accessLevel >= 10) {
            console.log(users)
            setPageAssignedToUsers(users.filter(u => u.assignedGraphPages?.find(ap => ap === activePage)))
        }
    }, [activePage, users]);

    const addLabelsFunc = () => {
        updatePageLabels(newLabels, activePage)
        setNewLabels('')
    }

    const removeLabelFunc = (e) => {
        removePageLabel(e.target.getAttribute('data-label-id'), activePage)
    }


    const assignUserToPageFunc = () => {
        updateUser({_id: selectedUserToAssign}, {$addToSet: {assignedGraphPages: activePage}})
    }
    const unAssignUserFromPageFunc = ({target}) => {
        updateUser({_id: target.getAttribute('data-user-id')}, {$pull: {assignedGraphPages: activePage}})
    }


    return <>
        <div className={'inboxFilters'}>
            {activePageData && <>
            <div onClick={_ => setChatsActive(!chatsActive)}
                 className={`socBoxBtn ${chatsActive && 'active'}`}>Chats
            </div>
            <div onClick={_ => setFbCommentsActive(!fbCommentsActive)}
                 className={`socBoxBtn ${fbCommentsActive && 'active'}`}>FB comments
            </div>
            <div onClick={_ => setIgCommentsActive(!igCommentsActive)}
                 className={`socBoxBtn ${igCommentsActive && 'active'}`}>IG comments
            </div>

                <div
                    className={`socBoxBtn ${activePageData.notificationCount === 0 && 'active'}`}
                    onClick={readAllFunc}>🔔 = 0
                </div>
                <a className={'socBoxBtn '} href={`https://fb.com/${activePage}`} target={'_blank'}>
                    🔗
                </a></>}
            <div
                className={`socBoxBtn ${showLabels && 'active'}`}
                onClick={() => {
                    setShowLabels(!showLabels)
                }}>👁 🏷
            </div>
            {user?.accessLevel >= 10 && <>
                <div
                    className={`socBoxBtn ${showEditTags && 'active'}`}
                    onClick={() => {
                        setShowEditTags(!showEditTags)
                    }}>✎ 🏷
                </div>
                {showEditTags && <>
                    <div className={"labels"}>
                        <input type={"text"} className={'socBoxInput'} value={newLabels}
                               onChange={e => setNewLabels(e.target.value)}/>

                    </div>
                    <div className={"socBoxBtn"} onClick={addLabelsFunc}>Add 🏷</div>
                </>
                }

            </>}
        </div>
        {showLabels && <div className={'pageLabels'}>

            {activePageData?.labels.map(lb => <div className={'socBoxLabel'}
                                                   title={lb.labelText}><span>{lb.emoji} {lb.labelText}
                {!graphLoading && user?.accessLevel >= 10 && lb.type === 'simple' &&
                    <strong data-label-id={lb._id} onDoubleClick={removeLabelFunc}
                            title={'double click to remove'}>❌</strong>}</span>
            </div>)}
        </div>}
        {user?.accessLevel >= 10 &&
            <div style={{padding: "0 0 10px 0"}}>
                <strong> Assign user:
                    <select value={selectedUserToAssign}
                            onChange={({target}) => setSelectedUserToAssign(target.value)}>
                        <option></option>
                        {users.filter(a => a.assignedGraphPages.indexOf(activePage) === -1).map(us => <option
                            value={us._id} key={'opt' + us._id}>{us.username}</option>)}
                    </select>
                    <strong className={'socBoxBtn'} onClick={assignUserToPageFunc}>Assign</strong>
                </strong>

                || Page Assigned to: {pageAssignedToUsers.map(us => <span className={'assignedUser'}
                                                                          key={us._id}>🙋{us.username}
                <strong data-user-id={us._id} onDoubleClick={unAssignUserFromPageFunc}
                        title={'double click to remove'}> ❌ </strong> </span>)}

            </div>}
    </>
};
export default ActivePageActions;
