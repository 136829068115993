import React, {useReducer, useContext, createContext} from 'react';

import reducer from './reducer';

import {
    INBOX_FETCH_BEGIN,
    INBOX_FETCH_SUCCESS,
    INBOX_FETCH_ERROR,
    PAGES_LIST_BEGIN,
    PAGES_LIST_SUCCESS,
    PAGES_LIST_ERROR,
    STORED_CONTROLLED_USERS,
    TOGGLE_PAGE_SYNC_ERROR,
    TOGGLE_PAGE_SYNC_BEGIN,
    REPLY_TO_COMMENT_SUCCESS,
    REPLY_TO_COMMENT_ERROR,
    DELETE_COMMENT_SUCCESS,
    DELETE_COMMENT_ERROR,
    DELETE_COMMENT_BEGIN,
    REPLY_TO_CHAT_SUCCESS,
    REPLY_TO_CHAT_ERROR,
    CREATIVES_FETCH_BEGIN,
    CREATIVES_FETCH_SUCCESS,
    CREATIVES_FETCH_ERROR,
    REACT_TO_COMMENT_SUCCESS,
    REACT_TO_COMMENT_ERROR,
    UPDATE_PAGE_LABELS_ERROR,
    UPDATE_PAGE_LABELS_SUCCESS,
    UPDATE_PAGE_LABELS_BEGIN,
} from './actions';

import axios from '../../config/axiosConfig'
import {wait} from '../../utils/utils'

const initialState = {
    graphLoading: true,
    errorMessage: '',
    pageInbox: {pageId: "", fbComments: [], chats: [], igComments: []},
    creatives: [],
    graphUsers: [],
    graphPages: [],
    allReadPage: ""
}

const GraphContext = createContext();

const GraphProvider = ({children}) => {

    const [state, dispatch] = useReducer(reducer, initialState);

    const fetchPagesList = async () => {
        try {
            dispatch({type: PAGES_LIST_BEGIN, payload: {graphPages: []}});

            let [resp, blank] = await Promise.all([axios.get('/fetchPagesList'), wait(0.5)])
            dispatch({
                type: PAGES_LIST_SUCCESS,
                payload: {
                    graphPages: resp.data
                }
            });

            //todo dispatch success

        } catch (e) {
            console.log(e)
            dispatch({
                type: PAGES_LIST_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }
    }

    const getPageInbox = async (pageId) => {
        try {
            dispatch({type: INBOX_FETCH_BEGIN});

            let [resp, blank] = await Promise.all([axios.get(`/getPageInbox?pageId=${pageId}`), wait(0.5)])
            dispatch({
                type: INBOX_FETCH_SUCCESS,
                payload: {
                    pageInbox: resp.data
                }
            });

            //todo dispatch success

        } catch (e) {
            console.log(e)
            dispatch({
                type: INBOX_FETCH_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }
    }
    const fetchCreatives = async (pageId) => {
        try {
            dispatch({type: CREATIVES_FETCH_BEGIN});

            let [resp, blank] = await Promise.all([axios.get(`/fetchCreatives`), wait(0.5)])
            dispatch({
                type: CREATIVES_FETCH_SUCCESS,
                payload: {
                    creatives: resp.data
                }
            });

            //todo dispatch success

        } catch (e) {
            console.log(e)
            dispatch({
                type: CREATIVES_FETCH_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }
    }
    const replyToComment = async (commentId, message) => {
        try {

            let [resp, blank] = await Promise.all([axios.post(`/replyToComment`, {commentId, message}), wait(0.2)])
            if (resp.data.msg === 'ok') {
                let {pageId, fbComments, igComments, chats} = state.pageInbox
                dispatch({
                    type: REPLY_TO_COMMENT_SUCCESS,
                    payload: {
                        pageInbox: {
                            pageId,
                            chats,
                            igComments,
                            fbComments: [...fbComments.filter(c => c.id !== resp.data.updatedComment.id), resp.data.updatedComment]
                        }
                    }
                });
            } else dispatch({
                type: REPLY_TO_COMMENT_ERROR,
                payload: {
                    errorMessage: resp.data.msg
                }
            });

            //todo dispatch success

        } catch (e) {
            console.log(e)
            dispatch({
                type: REPLY_TO_COMMENT_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }
    }
    const reactToComment = async (commentId,) => {
        try {

            let [resp, blank] = await Promise.all([axios.post(`/reactToComment`, {commentId}), wait(0.2)])
            if (resp.data.msg === 'ok') {
                dispatch({
                    type: REACT_TO_COMMENT_SUCCESS,
                    payload: {}
                });
            } else dispatch({
                type: REACT_TO_COMMENT_ERROR,
                payload: {
                    errorMessage: resp.data.msg
                }
            });

            //todo dispatch success

        } catch (e) {
            console.log(e)
            dispatch({
                type: REACT_TO_COMMENT_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }
    }
    const replyToIgComment = async (commentId, message) => {
        try {

            let [resp, blank] = await Promise.all([axios.post(`/replyToIgComment`, {commentId, message}), wait(0.2)])
            if (resp.data.msg === 'ok') {
                let {pageId, fbComments, igComments, chats} = state.pageInbox
                dispatch({
                    type: REPLY_TO_COMMENT_SUCCESS,
                    payload: {
                        pageInbox: {
                            pageId,
                            chats,
                            fbComments,
                            igComments: [...igComments.filter(c => c.id !== commentId), resp.data.updatedComment]
                        }
                    }
                });
            } else dispatch({
                type: REPLY_TO_COMMENT_ERROR,
                payload: {
                    errorMessage: resp.data.msg
                }
            });

            //todo dispatch success

        } catch (e) {
            console.log(e)
            dispatch({
                type: REPLY_TO_COMMENT_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }
    }
    const deleteComment = async (commentId) => {
        try {

            dispatch({type: DELETE_COMMENT_BEGIN});
            try {
                axios.post(`/deleteComment`, {commentId})
            } catch (e) {

            }
            let [blank] = await Promise.all([wait(0.2)])


            let {pageId, fbComments, igComments, chats} = state.pageInbox
            dispatch({
                type: DELETE_COMMENT_SUCCESS,
                payload: {
                    pageInbox: {
                        pageId,
                        chats,
                        igComments,
                        fbComments: fbComments.filter(c => c.id !== commentId)
                    }
                }
            });

            //todo dispatch success

        } catch (e) {
            console.log(e)
            dispatch({
                type: DELETE_COMMENT_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }
    }

    const deleteNestedComment = async (commentId) => {
        try {

            try {
                axios.post(`/deleteNestedComment`, {commentId})
            } catch (e) {

            }
            let [blank] = await Promise.all([wait(0.2)])


            let {pageId, fbComments, igComments, chats} = state.pageInbox
            let updatedCommentList = [...fbComments]

            updatedCommentList.forEach(c => {
                c.replies = c.replies.filter(r => r.id !== commentId)
                c.replies?.forEach(nr => nr.replies = nr.replies?.filter(nrr => nrr.id !== commentId))
            })


            dispatch({
                type: DELETE_COMMENT_SUCCESS,
                payload: {
                    pageInbox: {
                        pageId,
                        chats,
                        igComments,
                        fbComments: updatedCommentList
                    }
                }
            });

            //todo dispatch success

        } catch (e) {
            console.log(e)
            dispatch({
                type: DELETE_COMMENT_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }
    }

    const replyToChat = (recipient, message, platform) => {
        return new Promise(async (resolve, reject) => {
            try {
                let {pageId} = state.pageInbox
                let [resp, blank] = await Promise.all([axios.post(`/replyToChat`, {
                    pageId,
                    recipient,
                    message,
                    platform
                }), wait(0.2)])

                if (resp.data.msg === 'ok') {
                    dispatch({
                        type: REPLY_TO_CHAT_SUCCESS,
                        payload: {}
                    });
                    return resolve(resp.data.newMsg)
                } else dispatch({
                    type: REPLY_TO_CHAT_ERROR,
                    payload: {
                        errorMessage: resp.data.msg
                    }
                });

                //todo dispatch success

            } catch (e) {
                console.log(e)
                dispatch({
                    type: REPLY_TO_COMMENT_ERROR,
                    payload: {
                        errorMessage: e.message
                    }
                });
            }
            return resolve(false)
        })
    }

    const uploadFile = (formData) => {
        return new Promise(async (resolve, reject) => {
            try {
                let [resp, blank] = await Promise.all([await axios.post('/uploadFile', formData, {headers: {"Content-Type": "multipart/form-data"}}), wait(0.2)])

                if (resp.data.msg === 'ok') {
                    dispatch({
                        type: REPLY_TO_CHAT_SUCCESS,
                        payload: {}
                    });
                    return resolve(resp.data.newMsg)
                } else dispatch({
                    type: REPLY_TO_CHAT_ERROR,
                    payload: {
                        errorMessage: resp.data.msg
                    }
                });

                //todo dispatch success

            } catch (e) {
                console.log(e)
                dispatch({
                    type: REPLY_TO_COMMENT_ERROR,
                    payload: {
                        errorMessage: e.message
                    }
                });
            }
            return resolve(false)
        })
    }
    const togglePageSync = async (pageId, disabled) => {
        try {
            dispatch({type: TOGGLE_PAGE_SYNC_BEGIN});

            let [resp, blank] = await Promise.all([axios.post(`/togglePageSync`, {pageId, disabled}), wait(0.2)])
            let newState = [...state.graphPages]
            newState.find(pg => pg.id === pageId).disabled = disabled
            if (resp.data.msg === 'ok') dispatch({
                type: INBOX_FETCH_SUCCESS,
                payload: {
                    graphPages: newState
                }
            });

            //todo dispatch success

        } catch (e) {
            console.log(e)
            dispatch({
                type: TOGGLE_PAGE_SYNC_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }
    }
    const togglePageSyncBulk = async (pageList, disabled) => {
        try {
            dispatch({type: TOGGLE_PAGE_SYNC_BEGIN});

            let [resp, blank] = await Promise.all([axios.post(`/togglePageSyncBulk`, {pageList, disabled}), wait(0.2)])
            let newState = [...state.graphPages]
            newState.forEach(pg => {
                if (pageList.includes(pg.id)) pg.disabled = disabled
            })
            if (resp.data.msg === 'ok') dispatch({
                type: INBOX_FETCH_SUCCESS,
                payload: {
                    graphPages: newState
                }
            });

            //todo dispatch success

        } catch (e) {
            console.log(e)
            dispatch({
                type: TOGGLE_PAGE_SYNC_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }
    }
    const readAllNotif = async (pageId) => {
        try {
            let [resp, blank] = await Promise.all([axios.post(`/readAllNotif`, {pageId}), wait(0.2)])
            let newState = [...state.graphPages]
            newState.find(pg => pg.id === pageId).notificationCount = 0
            if (resp.data.msg === 'ok') dispatch({
                type: INBOX_FETCH_SUCCESS,
                payload: {
                    graphPages: newState,
                    allReadPage: pageId
                }
            });

            //todo dispatch success

        } catch (e) {
            console.log(e)
            dispatch({
                type: TOGGLE_PAGE_SYNC_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }
    }
    const getControlledUsers = async () => {
        try {

            let [resp, blank] = await Promise.all([axios.get(`/getControlledUsers`), wait(0.5)])
            dispatch({
                type: STORED_CONTROLLED_USERS,
                payload: {
                    graphUsers: resp.data.guList,
                    graphPages: resp.data.pages || []
                }
            });

            //todo dispatch success

        } catch (e) {
            console.log(e)
            dispatch({
                type: STORED_CONTROLLED_USERS,
                payload: {
                    graphUsers: [],
                    graphPages: []
                }
            });
        }
    }

    const updatePageLabels = async (labels, pageId) => {
        try {
            dispatch({type: UPDATE_PAGE_LABELS_BEGIN});

            let [blank, resp] = await Promise.all([
                wait(0.2),
                axios.post(`/assignLabel`, {entityType: "graphPage", labels, id: pageId})])

            dispatch({
                type: UPDATE_PAGE_LABELS_SUCCESS,
                payload: {
                    graphPages: [...state.graphPages.filter(c => c.id !== pageId), resp.data.updatedEntity]

                }
            });

            //todo dispatch success

        } catch (e) {
            console.log(e)
            dispatch({
                type: UPDATE_PAGE_LABELS_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }
    }

    const removePageLabel = async (labelId, pageId) => {
        try {
            dispatch({type: UPDATE_PAGE_LABELS_BEGIN});

            let [blank, resp] = await Promise.all([
                wait(0.8),
                axios.post(`/removeLabel`, {entityType: "graphPage", labelId, id: pageId})])

            dispatch({
                type: UPDATE_PAGE_LABELS_SUCCESS,
                payload: {
                    graphPages: [...state.graphPages.filter(c => c.id !== pageId), resp.data.updatedEntity]

                }
            });

            //todo dispatch success

        } catch (e) {
            console.log(e)
            dispatch({
                type: UPDATE_PAGE_LABELS_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }
    }
    return <GraphContext.Provider value={{
        ...state,
        fetchPagesList,
        getControlledUsers, deleteComment, deleteNestedComment, replyToChat, replyToIgComment,
        getPageInbox, togglePageSync, readAllNotif, togglePageSyncBulk, replyToComment,
        fetchCreatives, uploadFile, reactToComment, updatePageLabels,removePageLabel

    }}>
        {children}
    </GraphContext.Provider>
}


const useGraphContext = () => {
    return useContext(GraphContext);
}

export {GraphProvider, initialState, useGraphContext}