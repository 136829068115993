export const INBOX_FETCH_BEGIN = "INBOX_FETCH_BEGIN";
export const INBOX_FETCH_SUCCESS = "INBOX_FETCH_SUCCESS";
export const INBOX_FETCH_ERROR = "INBOX_FETCH_ERROR";

export const PAGES_LIST_BEGIN = "PAGES_LIST_BEGIN";
export const PAGES_LIST_SUCCESS = "PAGES_LIST_SUCCESS";
export const PAGES_LIST_ERROR = "PAGES_LIST_ERROR";

export const REACT_TO_COMMENT_SUCCESS = "REACT_TO_COMMENT_SUCCESS"
export const REACT_TO_COMMENT_ERROR = "REACT_TO_COMMENT_ERROR"
export const STORED_CONTROLLED_USERS = 'STORED_CONTROLLED_USERS'

export const TOGGLE_PAGE_SYNC_BEGIN = "TOGGLE_PAGE_SYNC_BEGIN"
export const TOGGLE_PAGE_SYNC_ERROR = "TOGGLE_PAGE_SYNC_ERROR"
export const REPLY_TO_COMMENT_SUCCESS = "REPLY_TO_COMMENT_SUCCESS"
export const REPLY_TO_COMMENT_ERROR = "REPLY_TO_COMMENT_ERROR"
export const DELETE_COMMENT_BEGIN = "DELETE_COMMENT_BEGIN"
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS"
export const DELETE_COMMENT_ERROR = "DELETE_COMMENT_ERROR"
export const REPLY_TO_CHAT_SUCCESS = "REPLY_TO_CHAT_SUCCESS"
export const REPLY_TO_CHAT_ERROR = "REPLY_TO_CHAT_ERROR"

export const CREATIVES_FETCH_ERROR = "CREATIVES_FETCH_ERROR"
export const CREATIVES_FETCH_SUCCESS = "CREATIVES_FETCH_SUCCESS"
export const CREATIVES_FETCH_BEGIN = "CREATIVES_FETCH_BEGIN"

export const UPDATE_PAGE_LABELS_SUCCESS = "UPDATE_PAGE_LABELS_SUCCESS"
export const UPDATE_PAGE_LABELS_ERROR = "UPDATE_PAGE_LABELS_ERROR"
export const UPDATE_PAGE_LABELS_BEGIN = "UPDATE_PAGE_LABELS_BEGIN"

