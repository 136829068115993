import {timeSince, wait} from "../../../../utils/utils";
import {useEffect, useState} from "react";
import axios from "../../../../config/axiosConfig";
import Loader from "../../loader/Loader";
import {useGraphContext} from "../../../../context/graphContext/context";


const IgCommentItem = ({id, timestamp, mediaId, expanded, name, text, replies}) => {
    const [lastLoad, setLastLoad] = useState(10);
    const [mediaData, setMediaData] = useState(null);
    const [reply, setReply] = useState('')
    const [deleteConfirm, setDeleteConfirm] = useState(false)
    const {replyToIgComment, deleteComment} = useGraphContext()


    useEffect(() => {
        const getMediaData = async () => {
            let [resp, blank] = await Promise.all([axios.get(`/getMediaData?mediaId=${mediaId}`), wait(0.5)])
            console.log(resp)
            setMediaData(resp.data)
        }
        if (expanded && lastLoad + 15 * 60 * 1000 < Date.now()) {
            setLastLoad(Date.now())
            getMediaData()

        } else console.log(lastLoad)
    }, [expanded])


    const replyToCommentFunc = () => {
        if (reply) replyToIgComment(id, reply)
        setReply('')
    }
    return <div className="commentItem">

        <div className={'heading'}>

        </div>
        <div className={'itemHeading'}>
            <a href={'#'} target={'_blank'}><img src={'/ig-logo.png'} className={'logo'}/></a>

            <span>  <strong
                className={'username'}>{name || "Private User"}:</strong> {text.slice(0, 275)}{text.length > 275 && "..."}  </span>
            <small className={'timeSince'}>{timeSince(timestamp)} ago</small>
        </div>
        {expanded&& <div className={'actions'}>
            <textarea placeholder={'reply to comment'}
                      onChange={e => setReply(e.target.value)}
                      value={reply}></textarea>
            <button onClick={replyToCommentFunc}>Send</button>
            <a href={`https://instagram.com/p/${mediaData?.shortcode}`} target={"_blank"}>
                <button>Open Post</button>
            </a>


        </div>}
        {expanded && <div className={'itemContent'}>
            {!mediaData && <Loader/>}
            {!!mediaData && <>
                <div className={'feedData'}>
                    <img src={mediaData.thumbnail_url || mediaData.media_url}/>
                    <span>{mediaData.caption}</span>
                </div>
                <div className={'commentInfo'}>
                    <h4>Replies:</h4>
                    {!replies.length ? "No Replies" : replies.map((rep) => (
                        <div key={rep.timestamp}><strong className={'username'}>{rep.name}: </strong><span>{rep.text}</span></div>))}

                </div>
            </>}
        </div>}
    </div>
};
export default IgCommentItem;
