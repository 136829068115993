import "./AdsPage.css";
import {useGraphContext} from "../../../context/graphContext/context";
import Loader from "../../partials/loader/Loader";
import {useEffect, useState} from "react";
import {removeDuplicates, timeSince, wait} from "../../../utils/utils";
import InboxItem from "../../partials/inboxItem/InboxItem";

const AdsPage = () => {
    const {creatives, graphLoading, fetchCreatives} = useGraphContext();
    const [fullAdInfo, setFullAdInfo] = useState(null)

    useEffect(() => {
        fetchCreatives()
    }, [])
    //   console.log(creatives.filter(c => c.crAds.length > 1))
    let ads = [], campaigns = []
    creatives.forEach(c => {
        campaigns.push(...c.crCamps)
        ads.push(...c.crAds)
    })


    console.log(fullAdInfo)
    if (creatives.length === 0 && graphLoading) return <Loader bigLoader={true}/>
    return <div>
        <div className={'creativesSummary'}>
            <strong>{creatives.length}</strong> Creatives boosted by <strong>{ads.length}</strong> Active Ads
            (<strong>{campaigns.filter(c => c.startTime > Date.now()).length}</strong> scheduled)
        </div>
        <div className={'creativesList'}>
            {creatives.map(({cr, crAds, crCamps, crSets}) => {
                return <div key={cr.id} className={'abItem'}
                            onClick={() => setFullAdInfo([{cr, crAds, crCamps, crSets}])}>
                    <div className={'adItemName'} title={crCamps[0].name}><strong style={{color:"gray"}}>{crSets[0].learning_stage_info?.status}</strong> {crCamps[0].name}</div>
                </div>
            })}


            {fullAdInfo && fullAdInfo.map(({cr, crAds, crCamps, crSets}) => {
                return (<div key={cr.id} className={'fullAdInfoCover'}>
                    <div className={'fullAdInfo'}>
                        <span onClick={() => setFullAdInfo(null)} className={'closeInfo'}>❌ </span>
                        <div className={'creativeItem'}>
                            <a href={`https://fb.com/${cr.feedData?.id}`} target={"_blank"}><img width={240}
                                                                                                 src={cr?.feedData?.full_picture}/></a>
                            <div className={'creativeData'}>
                                {cr.body}
                            </div>
                        </div>
                        <hr/>
                        {crCamps.map(({id, created, status, effective_status, name, objective, startTime}) => {

                            return (<div key={cr.id + id}>
                                <span><u>Name:</u> <strong>"{name}"</strong>; created <strong>{timeSince(created)} ago</strong>; Start date: <strong>{new Date(startTime).toDateString()}</strong></span><br/>
                                <span><u>Campaign Status:</u> <strong>{status} ({effective_status})</strong> <small>id: {id}</small></span><br/>
                                <span><u>Campaign objective:</u> <strong>{objective}</strong></span>
                            </div>)
                        })}
                        <hr/>
                        {crSets.map(({
                                         effective_status,
                                         id,
                                         name,
                                         status,
                                         bid_strategy,
                                         billing_event,
                                         learning_stage_info
                                     }) => {
                            return (<div key={cr.id + id}>
                                <span><u>Name:</u> {name}</span><br/>
                                <span><u>AdSet Status:</u> <strong>{status} ({effective_status})</strong> <small>id: {id}</small></span><br/>
                                <span><u>Bid Strategy:</u> <strong>{bid_strategy}</strong></span><br/>
                                <span><u>Billing Event:</u> <strong>{billing_event}</strong></span><br/>
                                <span><u>Delivery Estimate:</u> <strong></strong></span><br/>
                                <span><u>Learning stage info:</u> <strong>{learning_stage_info?.status}</strong>; Conversions: {learning_stage_info?.conversions}</span>
                            </div>)

                        })}
                        <hr/>
                        {crAds.map(({id, created, effective_status, targeting, status}) => {
                            let {geo_locations, age_min, age_max, flexible_spec, genders} = targeting
                            let flexible = flexible_spec?.map(({interests}) => interests.map(i => `"${i.name}"`).join(', '))
                            let gender = genders?.map(num => ['all', 'Male', "Female"][num])
                            console.log(targeting)
                            return (<div key={cr.id + id}>
                                <span><u>Ad Effective Status:</u> <strong>{status} ({effective_status})</strong> <small>id: {id}</small></span><br/>
                                <span><u>Ad created:</u> <strong>{timeSince(created)} ago</strong></span><br/>
                                <span><u>Ad Targeting:</u> <strong>Gender: </strong> {gender || 'not specified'}; <strong>Country:</strong> {geo_locations?.countries?.join(', ')}; <strong>Age:</strong> {age_min} ~ {age_max}; <strong>Interests:</strong>: {flexible || "Not specified"}</span>
                            </div>)
                        })}
                        <hr/>

                    </div>
                </div>)
            })}
        </div>
    </div>
};
export default AdsPage;
/*
* return (<div key={cr.id} className={'creativeItem'}>
                    <img src={cr?.feedData?.full_picture}/>
                    <div className={'content'}>
                        <div>
                            {crAds.length} Ads Running: <br/>
                            {crSets.map(s => {
                                let camp = crCamps.find(c => c.id === s.campaignId)
                                return <li><small><strong style={{color: "gold"}}>{camp.name}</strong> > {s.name}
                                </small> {Date.now() < camp.startTime && <small
                                    style={{color: "cyan"}}>(starts: {new Date(camp.startTime).toLocaleDateString()})</small>}
                                </li>
                            })}

                            {cr.feedData && <a href={`https://fb.com/${cr.feedData.id}`} target={"_blank"}>
                                <button>Open Post</button>
                            </a>}
                        </div>
                        <div className={'crMsg'}>{cr?.feedData?.message}</div>
                        <ul>
                            {cr?.comments?.map(com => <InboxItem type="fbc" noPreview={true} key={com.id} {...com}/>)}
                        </ul>
                    </div>
                </div>)
* */