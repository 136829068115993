import "./InboxFilterSelect.css";
import {useEffect, useState} from "react";
import Select from 'react-select'
import {useAdminContext} from "../../../context/adminContext/context";

const InboxFilterSelect = ({syncFilters}) => {
    const [filters, setFilters] = useState([])
    const {labels, getLabels} = useAdminContext()
    const [selectGroups, setSelectGroups] = useState([])
    useEffect(() => {
        syncFilters(filters)
    }, [filters])

    useEffect(() => {
        console.log('initial filter set')
        let groups = [], filt = []
        labels.forEach(l => {
            let group = groups.find(o => o.label === l.filterGroup)
            if (!group) {
                group = {label: l.filterGroup, options: []}
                groups.push(group)
                filt.push([])
            }
            group.options.push({value: l.labelText, label: `${l.emoji} ${l.labelText}`})
        })
        setSelectGroups(groups)
        setFilters(filt)
    }, [])

    return <div className={'inboxFilterBar'}>
        {selectGroups.map((group, index) => <Select key={group.label} options={group.options}
                                                    placeholder={`Filter ${group.label}`}
                                                    closeMenuOnSelect={false} isMulti
                                                    onChange={e => {
                                                        let newFilters = [...filters]
                                                        if (!newFilters[index]) newFilters[index] = []
                                                        newFilters[index] = e.map(a => a.value)
                                                        setFilters(newFilters)
                                                    }}
                                                    value={filters[index]?.map((value => ({
                                                        value,
                                                        label: value
                                                    }))) || []}
                                                    styles={{
                                                        option: (styles) => ({...styles, color: 'black'}),
                                                        multiValue: (styles) => ({
                                                            ...styles,
                                                            color: "black",
                                                            display: 'flex',
                                                            flexDirection: "row"
                                                        })
                                                    }}></Select>)}

    </div>
};
export default InboxFilterSelect;
