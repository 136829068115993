import "./ControlPanelPage.css";
import {useAdminContext} from "../../../context/adminContext/context";
import {useEffect, useState} from "react";
import {randomString} from "../../../utils/utils";
import {useGraphContext} from "../../../context/graphContext/context";
import {useUserContext} from "../../../context/userContext/context";
import axios from "../../../config/axiosConfig";
import Loader from "../../partials/loader/Loader";
import {wait} from '../../../utils/utils'


const ControlPanelPage = () => {

    const {
        adminLoading,
        users,
        labels,
        getUsers,
        createNewUser,
        updateUser,
        deleteUser,
        toggleGraphAssigned,
        getLabels,
        updateLabelMeta
    } = useAdminContext()
    const {
        graphLoading,
        getControlledUsers,
        graphUsers,
        graphPages,
        togglePageSync,
        togglePageSyncBulk,
        updatePageLabels,
        removePageLabel
    } = useGraphContext()
    const {user} = useUserContext()
    const [showUserActions, setShowUserActions] = useState(false)
    const [userData, setUserData] = useState({username: "", password: "", email: "", accessLevel: 1})
    const [userUpdateData, setUserUpdate] = useState({})
    const [deleteConfirm, setDeleteConfirm] = useState(null)
    const [showGraphActions, setShowGraphActions] = useState(false)
    const [showLabelActions, setShowLabelActions] = useState(false)
    const [assignSelect, setAssignSelect] = useState({})
    const [pagesScanning, setPagesScanning] = useState(false)
    const [editedLabels, setEditedLabels] = useState({})
    const [newLabelText, setNewLabelText] = useState(`Label #${labels.length + 1}`)
    const [newLabelFilterGroup,setNewLabelFilterGroup] = useState('Language')
    const [newLabelEmoji, setNewLabelEmoji] = useState('🏷')
    const [newLabels,setNewLabels] = useState({})

    useEffect(() => {
        getUsers()
        getLabels()
        getControlledUsers()
    }, [])
    useEffect(() => {
        setNewLabelText(`Label #${labels.length}`)
    }, [labels])

    const syncFullUserData = async (id) => {
        setPagesScanning(true)
        await axios.get(`/syncFullUserData?graphUserId=${id}`)

        getControlledUsers()
        await wait(0.5)
        setPagesScanning(false)
    }

    const editLabelState = ({target}) => {
        let labelId = target.getAttribute('data-label-id')
        let prop = target.getAttribute('data-prop')
        let {value} = target
        let updatedLabels = {...editedLabels}
        if (!updatedLabels['lb' + labelId]) updatedLabels['lb' + labelId] = {}
        updatedLabels['lb' + labelId][prop] = value
        setEditedLabels(updatedLabels)

    }
    const updateLabelFunc = ({target}) => {
        let _id = target.getAttribute('data-label-id')
        updateLabelMeta({_id}, editedLabels['lb' + _id])
    }
    const createCustomTagFunc = () => {

        updateLabelMeta({labelText: newLabelText}, {emoji: newLabelEmoji, type: "mixed"})
    }
    const inputLabelsFunc = ({target}) => {
        let updatedLabels = {...newLabels}
       let pageId = target.getAttribute('data-page-id')
        updatedLabels[`pg${pageId}`] = target.value
        setNewLabels(updatedLabels)
    }
    const addLabelsFunc = ({target}) => {
        let pageId = target.getAttribute('data-page-id')
        updatePageLabels(newLabels[`pg${pageId}`], pageId)
        let updatedLabels = {...newLabels}
        updatedLabels[`pg${pageId}`] = ''
        setNewLabels(updatedLabels)
    }

    const removeLabelFunc = ({target})=>{
        let pageId = target.getAttribute('data-page-id')
        let labelId = target.getAttribute('data-label-id')
        removePageLabel(labelId, pageId)
    }


    return <div>
        {/** Users Manager Start*/}
        {user?.accessLevel >= 10 && <div className={'adminActionTitle'}>
            <h3 onClick={_ => setShowUserActions(!showUserActions)}>Users</h3>
            {showUserActions && <div className={'adminAction'}>
                <div className={'form userForm'}>
                    <div>
                        <input onChange={(e) => {
                            setUserData({...userData, username: e.target.value})
                        }} value={userData.username} type={"text"} placeholder={'username'} name={'username'}/>
                        <input onChange={(e) => {
                            setUserData({...userData, email: e.target.value})
                        }} value={userData.email} type={"email"} placeholder={'email'} name={'email'}/>
                        <input onChange={(e) => {
                            setUserData({...userData, password: e.target.value})
                        }} value={userData.password} type={"text"} placeholder={'password'} name={'password'}/>
                        <select onChange={(e) => {
                            setUserData({...userData, accessLevel: Number(e.target.value)})
                        }} value={userData.accessLevel} name={'accessLevel'}>
                            <option value={1}>Blank User</option>
                            <option value={5}>All Pages User</option>
                            <option value={10}>Admin User</option>
                        </select>
                    </div>
                    <div><br/><br/><br/><br/>
                        <button style={{marginBottom: 10}} onClick={_ => {
                            setUserData({...userData, password: randomString()})
                        }}> Generate Password
                        </button>
                        <button onClick={() => createNewUser(userData)}> Create User</button>
                    </div>
                </div>
                <h4>Current Users</h4>
                {users.sort((k, l) => l.accessLevel - k.accessLevel).map((u, userIndex) => <div key={`user_${u._id}`}
                                                                                                className={'userActions form'}>
                    <strong> {u.username}</strong>
                    {!!userIndex && <>
                        <div className={'updateUser'}>
                            <select onChange={(e) => {
                                let update = {...userUpdateData};
                                update[`al_${u._id}`] = Number(e.target.value);
                                setUserUpdate(update)
                            }} value={u.accessLevel} name={'accessLevel'}>
                                <option value={1}>Blank User</option>
                                <option value={5}>All Pages User</option>
                                <option value={10}>Admin User</option>
                            </select>
                            <input type={'text'} placeholder={'change password'} value={userUpdateData[`p_${u._id}`]}
                                   onChange={(e) => {
                                       let update = {...userUpdateData};
                                       update[`p_${u._id}`] = e.target.value;
                                       setUserUpdate(update)
                                   }}/>
                            <button onClick={() => {
                                updateUser({_id: u._id}, {
                                    password: userUpdateData[`p_${u._id}`],
                                    accessLevel: userUpdateData[`al_${u._id}`]
                                })
                            }}>Update
                            </button>
                        </div>

                        {deleteConfirm !== u._id &&
                            <button className={'redBtn'} onClick={() => setDeleteConfirm(u._id)}>Delete</button>}
                        {deleteConfirm === u._id && <>
                            <button className={'orangeBtn'} onClick={() => setDeleteConfirm('')}>Cancel</button>
                            <button className={'redBtn'} onClick={() => deleteUser(deleteConfirm)}>Confirm</button>
                        </>}
                    </>}
                </div>)}
            </div>}
        </div>}
        {/**  Users Manager End*/}

        {/** Labels Manager Start*/}
        <div className={"adminActionTitle"}><h3 onClick={_ => setShowLabelActions(!showLabelActions)}>Edit Labels</h3>
            {showLabelActions && <div className={'adminAction'}>
                <table>
                    <thead>
                    <tr>
                        <th>Label</th>
                        <th>Edit Emoji</th>
                        <th>Edit Text</th>
                        <th>Edit Filter Group</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {labels?.sort((a, b) => a.labelText.localeCompare(b.labelText)).sort((a, b) => a.type.localeCompare(b.type))?.map(lb => <>
                        <tr key={lb._id}>
                            <td>
                                <div className={'socBoxLabel'} title={lb.labelText}> {lb.emoji} {lb.labelText}</div>

                            </td>
                            <td>
                                <input className={'socBoxInput short'} type={"text"}
                                       value={editedLabels[`lb${lb._id}`]?.emoji || lb.emoji} data-label-id={lb._id}
                                       data-prop={'emoji'} onChange={editLabelState}/>
                            </td>
                            <td>
                                <input className={'socBoxInput'}
                                       type={"text"}
                                       value={editedLabels[`lb${lb._id}`]?.labelText || lb.labelText}
                                       data-label-id={lb._id}
                                       data-prop={'labelText'} onChange={editLabelState}/>
                            </td>
                            <td>
                                <input className={'socBoxInput'}
                                       type={"text"}
                                       value={editedLabels[`lb${lb._id}`]?.filterGroup || lb.filterGroup}
                                       data-label-id={lb._id}
                                       data-prop={'filterGroup'} onChange={editLabelState}/>
                            </td>
                            <td>
                                {lb.type === 'simple' && <input type={"button"} value={'Save'} data-label-id={lb._id}
                                                                onClick={updateLabelFunc}/>}
                            </td>
                        </tr>
                        {lb.type === 'mixed' && <tr>
                            <td colSpan={5}>
                                <div className={'mixedLabelExtraInfo'}>
                                    <textarea className={'socBoxTextarea'} placeholder={"Write a simple description"}
                                              value={editedLabels[`lb${lb._id}`]?.description || lb.description}
                                              data-label-id={lb._id}
                                              data-prop={'description'} onChange={editLabelState}></textarea>
                                    {user?.accessLevel >= 10 && <>
                                        <div className={"labels"}>
                                            <input type={"text"} className={'socBoxInput'} style={{width: 420}}
                                                   value={editedLabels[`lb${lb._id}`]?.labels || lb.labels?.map(nlb => nlb.labelText)}
                                                   data-label-id={lb._id} placeholder={'Linked Labels'}
                                                   data-prop={'labels'} onChange={editLabelState}/>

                                        </div>
                                        <input type={"button"} value={'Save'} data-label-id={lb._id}
                                               onClick={updateLabelFunc}/>
                                    </>}
                                </div>
                            </td>
                        </tr>}
                    </>)}
                    </tbody>
                    <tfoot>
                    <tr>
                        <td>
                            <select>
                                <option>mixed</option>
                            </select>
                        </td>
                        <td>
                            <input className={'socBoxInput short'} type={"text"}
                                   value={newLabelEmoji} onChange={({target: {value}}) => setNewLabelEmoji(value)}/>
                        </td>
                        <td>
                            <input className={'socBoxInput'} type={"text"}
                                   value={newLabelText} onChange={({target: {value}}) => setNewLabelText(value)}/>
                        </td>
                        <td>
                            <input className={'socBoxInput'} type={"text"}
                                   value={newLabelFilterGroup} onChange={({target: {value}}) => setNewLabelFilterGroup(value)}/>
                        </td>
                        <td>
                            <input type={"button"} value={'Create'} onClick={createCustomTagFunc}/>
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </div>}
        </div>
        {/** Labels Manager Start*/}


        {/** Facebook Account Manager Start*/}
        <div className={'adminActionTitle'}>
            <h3 onClick={_ => setShowGraphActions(!showGraphActions)}>Linked Profiles <a
                href={`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_TEST_API_URL : process.env.REACT_APP_API_URL}/auth/facebook`}>
                <button>Link New FB Account</button>
            </a></h3>
            {showGraphActions && <div className={"adminAction"}>
                {graphUsers.map(gu => {
                    let thisUserPages = graphPages.filter(pg => pg.assignedUsers.indexOf(gu.id) !== -1)
                    return (<div key={gu._id} className={'graphData'}>
                        <div className={'usersPart'}>
                            <div>
                                <img src={gu.photo}/>
                                <div><strong>{gu.displayName}</strong><br/>
                                    <button style={{width: "fit-content"}}
                                            onClick={() => syncFullUserData(gu.id)}>Update Pages List
                                    </button>
                                </div>
                            </div>
                            {user.accessLevel >= 10 && <ul className={'assignedList'}>
                                <select
                                    value={assignSelect[`as_${gu._id}`]}
                                    onChange={(e) => {
                                        let update = {...assignSelect}
                                        update[`as_${gu._id}`] = e.target.value
                                        setAssignSelect(update)
                                    }}>
                                    <option disabled={true} value={''}>Assign to User</option>
                                    {users.filter(u => u.accessLevel < 5 && !u.assignedGraphUsers.find(agu => agu === gu._id)).map(u => {
                                        return (<option key={`access-toggler-${gu._id}_${u._id}`}
                                                        value={u._id}>{u.username}</option>)
                                    })}
                                </select><br/>
                                <button onClick={() => {
                                    toggleGraphAssigned(assignSelect[`as_${gu._id}`], gu._id, false)
                                    let update = {...assignSelect}
                                    update[`as_${gu._id}`] = ''
                                    setAssignSelect(update)
                                }}>Assign
                                </button>
                                {users.sort((l, k) => l.accessLevel - k.accessLevel).filter(u => u.accessLevel >= 5 || u.assignedGraphUsers.find(agu => agu === gu._id))
                                    .map((u, userIndex) => {
                                        return (<li key={`access-viewer-${gu._id}_${u._id}`}>
                                            {adminLoading && <span style={{fontSize: 15, padding: '0 12px'}}> ↺</span>}
                                            {!adminLoading && u.accessLevel < 5 && <span
                                                style={{
                                                    fontSize: 15,
                                                    padding: '0 12px',
                                                    cursor: "pointer",
                                                    color: "red"
                                                }}
                                                onClick={() => toggleGraphAssigned(u._id, gu._id, true)}>✖</span>}
                                            {u.accessLevel >= 5 &&
                                                <span style={{fontSize: 15, padding: '0 12px'}}> ✓</span>}
                                            <strong> {u.username}</strong>
                                        </li>)
                                    })}
                            </ul>}

                        </div>
                        <ul className={'pagesPart'}>
                            <li>
                                <button onClick={() => togglePageSyncBulk(thisUserPages.map(a => a.id), false)}>Select
                                    All
                                </button>
                                <button onClick={() => togglePageSyncBulk(thisUserPages.map(a => a.id), true)}>Remove
                                    All
                                </button>
                            </li>
                            {thisUserPages.length > 0 && thisUserPages.sort((a,b)=>a.name.localeCompare(b.name)).map(pg => {
                                return (<li key={`page-viewer-${gu._id}_${pg._id}`}>

                                   <div>
                                       {!graphLoading && <input checked={!pg.disabled} type={"checkbox"}
                                                             onChange={e => togglePageSync(pg.id, !e.target.checked)}/>}
                                    {graphLoading && <span style={{fontSize: 15, padding: '0 12px'}}> ↺</span>}
                                    <strong>{pg.name}</strong>
                                   </div>
                                    {pg?.labels.map(lb => <div className={'socBoxLabel'}
                                                                           title={lb.labelText}><span>{lb.emoji} {lb.labelText}
                                        {!graphLoading && user?.accessLevel >= 10 && lb.type === 'simple' &&
                                            <strong data-label-id={lb._id}  data-page-id={pg.id} onDoubleClick={removeLabelFunc}
                                                    title={'double click to remove'}>❌</strong>}</span>
                                    </div>)}
                                    <div className={"labelActions"}>
                                        <input type={"text"} className={'socBoxInput'} value={newLabels[`pg${pg.id}`]}
                                               onChange={inputLabelsFunc} data-page-id={pg.id}/>

                                    </div>
                                        <div className={"socBoxBtn"} onClick={addLabelsFunc} data-page-id={pg.id}>Add 🏷</div>



                                </li>)
                            })}
                            {thisUserPages.length === 0 && !pagesScanning &&
                                <strong>Click "Update Pages List" to Scan for pages</strong>}
                            {pagesScanning && <Loader bigLoader={true}/>}
                        </ul>
                    </div>)
                })}
            </div>
            }
        </div>
        {/** Facebook Account Manager End*/}
    </div>;
};
export default ControlPanelPage;
