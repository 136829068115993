import "./InboxItem.css";
import {timeSince} from "../../../utils/utils";
import ChatItem from "./chatItem/ChatItem";
import FbCommentItem from "./fbCommentItem/FbCommentItem";
import IgCommentItem from "./igCommentItem/IgCommentItem";
import {useEffect, useState} from "react";
import axios from "../../../config/axiosConfig";
import {useGraphContext} from "../../../context/graphContext/context";
import EmailItem from "./emailItem/EmailItem";

const InboxItem = (itemData) => {

    let {allReadPage} = useGraphContext()
    let {timestamp, doneAt, type, _id} = itemData
    const [expanded, setExpanded] = useState(false)
    const [done, setDone] = useState(timestamp < doneAt)

    const flagDone = async () => {
        await axios.post(`/flagDone/${type}?itemId=${_id}&pageId=${itemData.pageId}`)
        setDone(true)
        setExpanded(false)
    }
    useEffect(() => setExpanded(false), [allReadPage])

    return <div className={`inboxItem ${done && "done"}`} onClick={() => setExpanded(true)}
                onDoubleClick={() => setExpanded(false)}>

        {itemData.type === 'chat' && <ChatItem expanded={expanded} {...itemData}/>}
        {itemData.type === 'fbc' && <FbCommentItem expanded={expanded}  {...itemData}/>}
        {itemData.type === 'igc' && <IgCommentItem expanded={expanded} {...itemData}/>}
        {itemData.type === 'mail' && <EmailItem expanded={expanded} setDone={() => {
            setDone(true);
            setExpanded(false)
        }} {...itemData}/>}
        {expanded && <div className={'itemActions'}>
            <button onClick={flagDone}>Done!</button>
            <button disabled={true}>More Actions (Soon)</button>

        </div>}
    </div>
};
export default InboxItem;
