import React, {useReducer, useContext, createContext} from 'react';

import reducer from './reducer';

import {
    MAILS_LIST_FETCH_ERROR,
    MAILS_LIST_FETCH_BEGIN,
    MAILS_LIST_FETCH_SUCCESS,
    MAIL_RECIPIENTS_FETCH_BEGIN,
    MAIL_RECIPIENTS_FETCH_SUCCESS,
    MAIL_RECIPIENTS_FETCH_ERROR, MAIL_REPLY_BEGIN, MAIL_REPLY_SUCCESS, MAIL_REPLY_ERROR
} from './actions';

import axios from '../../config/axiosConfig'
import {wait} from '../../utils/utils'

const initialState = {
    emailLoading: true,
    errorMessage: '',
    recipients: [],
    emailInbox: {recipient: "", messages: []},
}

const EmailContext = createContext();

const EmailProvider = ({children}) => {

    const [state, dispatch] = useReducer(reducer, initialState);

    const getMailRecipients = async () => {
        try {
            dispatch({type: MAIL_RECIPIENTS_FETCH_BEGIN, payload: {recipients: []}});

            let [resp, blank] = await Promise.all([axios.get('/getMailRecipients'), wait(0.5)])
            dispatch({
                type: MAIL_RECIPIENTS_FETCH_SUCCESS,
                payload: {
                    recipients: resp.data.recipients
                }
            });

            //todo dispatch success

        } catch (e) {
            console.log(e)
            dispatch({
                type: MAIL_RECIPIENTS_FETCH_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }
    }

    const getRecipientMessages = async (recipientId) => {
        try {
            dispatch({type: MAILS_LIST_FETCH_BEGIN});

            let [resp, blank] = await Promise.all([axios.get(`/getRecipientMessages?recipient=${recipientId}`), wait(0.5)])
            dispatch({
                type: MAILS_LIST_FETCH_SUCCESS,
                payload: {
                    emailInbox: resp.data,
                }
            });

            //todo dispatch success

        } catch (e) {
            console.log(e)
            dispatch({
                type: MAILS_LIST_FETCH_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }
    }

    const sendGmailReply = async (base64MailContent, threadId, activeRecipient, doneItemId, callback) => {

        try {
            dispatch({type: MAIL_REPLY_BEGIN});

            let [resp, blank] = await Promise.all([axios.post(`/sendGmailReply`, {
                activeRecipient,
                base64MailContent,
                threadId, doneItemId
            }), wait(0.5)])
            if (!resp?.data?.errorMessage) callback()
            dispatch({
                type: MAIL_REPLY_SUCCESS,
                payload: {
                    ...resp.data
                }
            });

            //todo dispatch success

        } catch (e) {
            console.log(e)
            dispatch({
                type: MAIL_REPLY_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }
    }


    return <EmailContext.Provider value={{
        ...state,
        getMailRecipients,
        getRecipientMessages,
        sendGmailReply

    }}>
        {children}
    </EmailContext.Provider>
}


const useEmailContext = () => {
    return useContext(EmailContext);
}

export {EmailProvider, initialState, useEmailContext}