import {
    MAILS_LIST_FETCH_SUCCESS,
    MAILS_LIST_FETCH_BEGIN,
    MAILS_LIST_FETCH_ERROR,
    MAIL_RECIPIENTS_FETCH_ERROR,
    MAIL_RECIPIENTS_FETCH_SUCCESS,
    MAIL_RECIPIENTS_FETCH_BEGIN,
    MAIL_REPLY_BEGIN,
    MAIL_REPLY_SUCCESS,
    MAIL_REPLY_ERROR,

} from './actions';

const reducer = (state, action) => {
    //loading only
    if ([MAILS_LIST_FETCH_BEGIN, MAIL_REPLY_BEGIN, MAIL_RECIPIENTS_FETCH_BEGIN].indexOf(action.type) !== -1) {
        return {
            ...state,
            emailLoading: true,
            ...action.payload
        }
    }
    //Success
    if ([MAILS_LIST_FETCH_SUCCESS, MAIL_REPLY_SUCCESS, MAIL_RECIPIENTS_FETCH_SUCCESS].indexOf(action.type) !== -1) {

        return {
            ...state,
            emailLoading: false,
            ...action.payload
        }
    }

    //Errors
    if ([MAILS_LIST_FETCH_ERROR, MAIL_REPLY_ERROR, MAIL_RECIPIENTS_FETCH_ERROR].indexOf(action.type) !== -1) {
        return {
            ...state,
            emailLoading: false,
            errorMessage: action.payload.msg
        }
    }


    throw new Error(`no such action : ${action.type}`);
}

export default reducer;