import "./EmailInboxPage.css";
import {useEmailContext} from "../../../context/emailContext/context";
import ReadEmailComponent from "../../partials/readEmail/ReadEmailComponent";
import {timeSince} from "../../../utils/utils";
import {useEffect, useState} from "react";
import Loader from "../../partials/loader/Loader";
import InboxItem from "../../partials/inboxItem/InboxItem";


const EmailInboxPage = () => {
    let {emailLoading,emailInbox,recipients,getMailRecipients,getRecipientMessages} = useEmailContext()
    let [activeRecipient, setActiveRecipient] = useState('')
    useEffect(() => {
        getMailRecipients()
    }, [])

    useEffect(() => {
        if (activeRecipient) {
            getRecipientMessages(activeRecipient)
        }

    }, [activeRecipient])

    let mixedInbox = []
    emailInbox.messages?.forEach(c => {
        mixedInbox.push({type: 'mail', ...c})
    })
    return <div className={'container'}>
        <div className={'pagesSidebar'}>
            {recipients.sort((k, l) => l.lastNotification - k.lastNotification).map(r => (
                <div key={r._id} className={`pageItem ${activeRecipient === r.email && 'active'}`}
                     onClick={() => setActiveRecipient(r.email)}>

                    {r.email}
                    <div style={{textAlign: 'right'}}>
                        <strong className={'labelListPreview'}>{r.labels.map(lb => <span
                            title={lb.labelText}>{lb.emoji}</span>)}</strong>
                        <small>{r.notificationCount} new | Last {timeSince(r.lastNotification)} ago</small>
                    </div>
                </div>))}
        </div>
        <div className={'inboxList'}>
            {emailLoading && <Loader bigLoader={true}/>}
            {!emailLoading && mixedInbox.sort((a, b) => b.timestamp - a.timestamp).map(inb => <InboxItem key={inb.id} {...inb}/>)}
        </div>

    </div>;
};
export default EmailInboxPage;
