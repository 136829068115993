import {
    INBOX_FETCH_BEGIN,
    INBOX_FETCH_SUCCESS,
    INBOX_FETCH_ERROR,
    PAGES_LIST_BEGIN,
    PAGES_LIST_SUCCESS,
    PAGES_LIST_ERROR,
    STORED_CONTROLLED_USERS,
    TOGGLE_PAGE_SYNC_BEGIN,
    TOGGLE_PAGE_SYNC_ERROR,
    REPLY_TO_COMMENT_ERROR,
    REPLY_TO_COMMENT_SUCCESS,
    DELETE_COMMENT_ERROR,
    DELETE_COMMENT_SUCCESS,
    DELETE_COMMENT_BEGIN,
    REPLY_TO_CHAT_ERROR,
    REPLY_TO_CHAT_SUCCESS,
    CREATIVES_FETCH_BEGIN,
    CREATIVES_FETCH_SUCCESS,
    CREATIVES_FETCH_ERROR,
    REACT_TO_COMMENT_SUCCESS,
    REACT_TO_COMMENT_ERROR,
    UPDATE_PAGE_LABELS_SUCCESS, UPDATE_PAGE_LABELS_BEGIN, UPDATE_PAGE_LABELS_ERROR
} from './actions';

const reducer = (state, action) => {
    //loading only
    if ([UPDATE_PAGE_LABELS_BEGIN,INBOX_FETCH_BEGIN, PAGES_LIST_BEGIN, TOGGLE_PAGE_SYNC_BEGIN, DELETE_COMMENT_BEGIN,CREATIVES_FETCH_BEGIN].indexOf(action.type) !== -1) {
        return {
            ...state,
            graphLoading: true,
            ...action.payload
        }
    }
    //Success
    if ([UPDATE_PAGE_LABELS_SUCCESS,INBOX_FETCH_SUCCESS,REACT_TO_COMMENT_SUCCESS, PAGES_LIST_SUCCESS, STORED_CONTROLLED_USERS, REPLY_TO_COMMENT_SUCCESS, DELETE_COMMENT_SUCCESS, REPLY_TO_CHAT_SUCCESS,CREATIVES_FETCH_SUCCESS].indexOf(action.type) !== -1) {

        return {
            ...state,
            graphLoading: false,
            ...action.payload
        }
    }

    //Errors
    if ([UPDATE_PAGE_LABELS_ERROR,INBOX_FETCH_ERROR, PAGES_LIST_ERROR,REACT_TO_COMMENT_ERROR, TOGGLE_PAGE_SYNC_ERROR, REPLY_TO_COMMENT_ERROR, DELETE_COMMENT_ERROR, REPLY_TO_CHAT_ERROR,CREATIVES_FETCH_ERROR].indexOf(action.type) !== -1) {
        return {
            ...state,
            graphLoading: false,
            errorMessage: action.payload.msg
        }
    }


    throw new Error(`no such action : ${action.type}`);
}

export default reducer;