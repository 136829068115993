import "./GraphUsersPage.css";
import {useEffect, useState} from "react";
import {useGraphContext} from "../../../context/graphContext/context";
import axios from "../../../config/axiosConfig";

const GraphUsersPage = () => {
    let [guList, setGUList] = useState([])
    let {graphUsers, getControlledUsers} = useGraphContext()
    useEffect(() => {
        getControlledUsers()
    }, [])

    const syncFullUserData = async (id) => {
        await axios.get(`/syncFullUserData?graphUserId=${id}`)
        getControlledUsers()
    }


    return <div>
        Graph Users
        <a href={`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_TEST_API_URL : process.env.REACT_APP_API_URL}/auth/facebook`}>
            <button>Link New FB Account</button>
        </a>
        <h3>Linked Accounts</h3>
        {graphUsers.map(a => <div key={a._id} className={'graphUser'}>
            <img src={a.photo}/> <strong>{a.displayName}</strong>
            {!a.fullSync && <button style={{width: "fit-content"}} onClick={() => syncFullUserData(a.id)}>Sync</button>}
        </div>)}
    </div>
};
export default GraphUsersPage;
