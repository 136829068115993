import {timeSince, wait} from "../../../../utils/utils";
import {useEffect, useState} from "react";
import axios from "../../../../config/axiosConfig";
import Loader from "../../loader/Loader";
import {useGraphContext} from "../../../../context/graphContext/context";


const FbCommentItem = ({id, timestamp, name, expanded, text, replies, feedId, like_count, attachment, noPreview}) => {

    const [lastLoad, setLastLoad] = useState(10);
    const [feedData, setFeedData] = useState(null);
    const [reply, setReply] = useState('')
    const [nestedReply, setNestedReply] = useState({})
    const [deleteConfirm, setDeleteConfirm] = useState(false)
    const [nestedDelId, setNestedDelId] = useState('')
    const {replyToComment, reactToComment, deleteComment, deleteNestedComment} = useGraphContext()


    useEffect(() => {
        const getFeedData = async () => {
            let [resp, blank] = await Promise.all([axios.get(`/getFeedData?feedId=${feedId}`), wait(0.5)])
            console.log(resp)
            setFeedData(resp.data)
        }
        if (expanded && lastLoad + 15 * 60 * 1000 < Date.now()) {
            setLastLoad(Date.now())
            getFeedData()

        } else console.log(lastLoad)
    }, [expanded])

    const replyToCommentFunc = () => {
        if (reply) replyToComment(id, reply)
        setReply('')
    }
    const LikeCommentFunc = () => {
        reactToComment(id)
    }
    const deleteCommentFunc = () => {
        if (deleteConfirm) {
            deleteComment(id)
            setDeleteConfirm(false)
        } else setDeleteConfirm(true)
    }
    const deleteNestedCommentFunc = (e) => {
        let btnId = e.target.getAttribute('data-del-id')
        if (nestedDelId===btnId) {
            deleteNestedComment(btnId)
            setNestedDelId('')
        } else setNestedDelId(btnId)
    }
    const updateNestedReply = (e) => {
        let nr = {...nestedReply}
        nr[e.target.getAttribute('data-id')] = e.target.value
        setNestedReply(nr)
    }
    const replyToReplyFunc = (e) => {
        let comId = e.target.getAttribute('data-id')
        replyToComment(comId, nestedReply[comId])
        let nr = {...nestedReply}
        nr[comId] = ''
        setNestedReply(nr)
    }
    return <div className="commentItem">

        <div className={'heading'}>

        </div>
        <div className={'itemHeading'}>
            <a href={'#'} target={'_blank'}><img src={'/fb-logo.png'} className={'logo'}/></a>

            <span> <strong
                className={'username'}>{name || "Private User"}:</strong> {text.slice(0, 275)}{text.length > 275 && "..."}  </span>
            <small className={'timeSince'}>{timeSince(timestamp)} ago</small>
        </div>
        {expanded && <div className={'actions'}><textarea placeholder={'reply to comment'}
                                                          onChange={e => setReply(e.target.value)}
                                                          value={reply}></textarea>
            <button onClick={replyToCommentFunc}>Send</button>
            <button onClick={LikeCommentFunc}>Like Comment</button>
            <a href={`https://fb.com/${feedId}`} target={"_blank"}>
                <button>Open Post</button>
            </a>
            {deleteConfirm &&
                <button className={'orangeBtn'} onClick={() => setDeleteConfirm(false)}>Cancel</button>}
            <button onClick={deleteCommentFunc} className={"redBtn"}>{deleteConfirm ? "Confirm" : "Delete"}</button>
        </div>}
        {expanded && <div className={'itemContent'}>
            {!feedData && <Loader/>}
            {!!feedData && <>

                {!noPreview && <div className={'feedData'}>
                    <img src={feedData.full_picture}/>
                    <span>{feedData.message}</span>
                </div>}
                <div className={'commentInfo'}>
                    <strong>Likes:</strong> {like_count}
                    <h4>Replies:</h4>
                    {!replies.length ? "No Replies" : replies.map((rep) => (
                        <div key={rep.timestamp}>
                            <strong className={'username'}>{rep.name ||"Private User"}: </strong><span>{rep.text}</span> <span> </span>
                            {nestedDelId ===rep.id &&
                                <button className={'orangeBtn'} onClick={() => setNestedDelId('')}>Cancel</button>}
                            <button data-del-id={rep.id} onClick={deleteNestedCommentFunc}
                                    className={"redBtn"}>{nestedDelId ===rep.id ? "Confirm" : "Delete"}</button>
                            {rep.id && <div style={{paddingLeft: 15}}>
                                {!!rep.replies?.length && rep.replies.map((rr) => (
                                    <div key={rr.timestamp}>
                                        <strong className={'username'}>{rr.name ||"Private User"}: </strong><span>{rr.text}</span>
                                        {rr.id && <> <span> </span>
                                            {nestedDelId ===rr.id &&
                                                <button className={'orangeBtn'} onClick={() => setNestedDelId('')}>Cancel</button>}
                                            <button data-del-id={rr.id} onClick={deleteNestedCommentFunc}
                                                    className={"redBtn"}>{nestedDelId ===rr.id ? "Confirm" : "Delete"}</button>
                                        </>}
                                    </div>))}
                                <textarea rows={1} placeholder={'write nested reply'}
                                          value={nestedReply[rep.id] || ''}
                                          data-id={rep.id} style={{width: '100%'}}
                                          onChange={updateNestedReply}/>
                                {nestedReply[rep.id] &&
                                    <button data-id={rep.id} onClick={replyToReplyFunc}>Send</button>}




                            </div>}

                        </div>))}
                    {!!attachment && <> <br/><br/><h4>Attachments:</h4>
                        <span>{attachment?.type}</span><br/>
                        <img className={'attachmentImg'} src={attachment?.media?.image?.src || attachment?.url}/>
                    </>}
                </div>
            </>}
        </div>}
    </div>

};
export default FbCommentItem;
