

import {BrowserRouter, Route, Routes} from "react-router-dom";
import IndexPage from "./components/pages/index/IndexPage";
import NavBar from "./components/partials/navbar/NavBar";
import InboxPage from "./components/pages/inbox/InboxPage";
import AdsPage from "./components/pages/ads/AdsPage";
import LoginPage from "./components/pages/login/LoginPage";
import GraphUsersPage from "./components/pages/graphUsers/GraphUsersPage";
import ControlPanelPage from "./components/pages/controlPanel/ControlPanelPage";
import EmailInboxPage from "./components/pages/emailInbox/EmailInboxPage";
import PsalmPage from "./components/pages/psalm/PsalmPage";


function App() {
  return (
      <BrowserRouter>
        <div className="app">
<NavBar/>
          {/*App body  todo add route to backend const routeList!!!*/}
          <div className="app__body">
            <Routes>
                <Route
                    exact
                    path="/"
                    element={
                        <IndexPage/>
                    }
                />
                <Route
                    exact
                    path="/login"
                    element={
                        <LoginPage/>
                    }
                />
                <Route
                    exact
                    path="/graphUsers"
                    element={
                        <GraphUsersPage/>
                    }
                />
                <Route
                    exact
                    path="/inbox"
                    element={
                        <InboxPage/>
                    }
                />
                <Route
                    exact
                    path="/emailInbox"
                    element={
                        <EmailInboxPage/>
                    }
                />

                <Route
                    exact
                    path="/ads"
                    element={
                        <AdsPage/>
                    }
                />
                <Route
                    exact
                    path="/psalm"
                    element={
                        <PsalmPage/>
                    }
                />
                <Route
                    exact
                    path="/controlPanel"
                    element={
                        <ControlPanelPage/>
                    }
                />




            </Routes>
          </div>
        </div>
      </BrowserRouter>
  );
}

export default App;
