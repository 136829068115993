import {
    CREATE_USER_SUCCESS,
    STORE_USERS_LIST,
    GET_USER_LIST_BEGIN,
    GET_USERS_LIST_ERROR,
    CREATE_USER_BEGIN,
    UPDATE_USER_ERROR,
    DELETE_USER_ERROR,
    UPDATE_USER_BEGIN,
    CREATE_USER_ERROR,
    TOGGLE_GRAPH_ASSIGNED_BEGIN,
    TOGGLE_GRAPH_ASSIGNED_ERROR,
    GET_LABELS_LIST_ERROR,
    STORE_LABELS_LIST,
    UPDATE_LABEL_BEGIN
} from './actions';

const reducer = (state, action) => {

    if ([UPDATE_LABEL_BEGIN,GET_USER_LIST_BEGIN, UPDATE_USER_BEGIN, CREATE_USER_BEGIN, TOGGLE_GRAPH_ASSIGNED_BEGIN].indexOf(action.type) !== -1) {
        return {
            ...state,
            adminLoading: true
        }
    }
    //Success
    if ([STORE_LABELS_LIST,STORE_USERS_LIST, CREATE_USER_SUCCESS].indexOf(action.type) !== -1) {

        return {
            ...state,
            adminLoading: false,
            ...action.payload
        }
    }

    //errors
    if ([GET_LABELS_LIST_ERROR,UPDATE_USER_ERROR, DELETE_USER_ERROR, GET_USERS_LIST_ERROR, CREATE_USER_ERROR, TOGGLE_GRAPH_ASSIGNED_ERROR].indexOf(action.type) !== -1) {
        return {
            ...state,
            adminLoading: false,
            errorMessage: action.payload.msg
        }
    }

    throw new Error(`no such action : ${action.type}`);
}

export default reducer;