import "./Modal.css";
import {useState} from "react";

const Modal = ({children, className, buttonHtml}) => {
    let [visible, setVisible] = useState(false)
    return <div>
        <div className={'socBoxBtn '}
             onClick={() => setVisible(!visible)}
             style={{width: 'fit-content'}}>
            {buttonHtml || "Display Modal"}
        </div>
        {visible && <div className={`modalBody ${className}`}>
            <div className={'closeModal socBoxBtn'} onClick={() => setVisible(false)}> Close</div>
            {children}
        </div>}
    </div>
};
export default Modal;
