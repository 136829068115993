import {decodeBase64, decodeFileToBlob, encodeToBase64, timeSince, wait} from "../../../../utils/utils";
import {useEffect, useState} from "react";
import axios from "../../../../config/axiosConfig";
import Loader from "../../loader/Loader";
import {useGraphContext} from "../../../../context/graphContext/context";
import Modal from "../../modal/Modal";
import {renderToString} from "react-dom/server";
import {useEmailContext} from "../../../../context/emailContext/context";

const EmailItem = ({
                       _id,
                       expanded,
                       setDone,
                       timestamp,
                       subject,
                       snippet,
                       sender,
                       threadId,
                       messageId,
                       id,
                       labels,
                       recipient
                   }) => {

    const [lastLoad, setLastLoad] = useState(0);
    const [emailData, setEmailData] = useState(null);
    const [attachments, setAttachments] = useState([])
    const [reply, setReply] = useState('')
    const [htmlToRender, setHtml] = useState()
    const [hideConvo, setHideConvo] = useState(true)
    const {replyToChat, uploadFile} = useGraphContext()
    const {errorMessage, sendGmailReply} = useEmailContext()


    useEffect(() => {

        const downloadAttachmentData = async (att) => {
            let attachmentData = await axios.get(`/downloadAttachmentData?attachmentId=${att.attachmentId}&messageId=${id}&mimeType=${att.mimeType}`)
            setAttachments([...attachments.filter(a => a.attachmentId !== att.attachmentId), {
                attachmentId: att.attachmentId,
                value: attachmentData.data.data
            }])


        }
        for (let att of attachments) {
            console.log(att)
            if (att.value === null) {
                console.log(att)
                downloadAttachmentData(att)
            }
        }
    }, [attachments])

    useEffect(() => {
        const getMessages = async () => {
            let [resp, blank] = await Promise.all([axios.get(`/getGoogleMessageData?messageId=${id}`), wait(0.5)])
            setEmailData(resp.data.emailData)

        }
        if (expanded && lastLoad + 15 * 60 * 1000 < Date.now()) {

            getMessages()
            setLastLoad(Date.now())
        }
    }, [expanded])

    const renderParts = (partData, full = false) => {
        let htmlData, decodedBody
        switch (partData.mimeType) {
            case "text/html":
                decodedBody = decodeBase64(partData.body.data)
                //todo  htmlData = `The "${partData.mimeType}" mimeType requires testing for security reasons`
                if (!full && hideConvo) decodedBody = decodedBody.split('<blockquote>')[0].split('<div class="gmail_quote">')[0]
                htmlData = <div
                    dangerouslySetInnerHTML={{__html: decodedBody}}/>
                break
            case "text/plain":
                decodedBody = decodeBase64(partData.body.data)
                //       htmlData = decodedBody
                break
            case "multipart/alternative":
                if (partData.parts) htmlData = partData.parts.map(renderParts)
                break
            case "image/png":
            case "image/jpeg":
            case "image/jpg":
            case "image/gif":
                let imageData = attachments.find(a => a.attachmentId === partData.body.attachmentId)
                if (!imageData) setAttachments([...attachments, {
                    attachmentId: partData.body.attachmentId,
                    value: null
                }])
                if (imageData?.value) htmlData =
                    <img style={{maxWidth: '100%', maxHeight: 500}}
                         src={URL.createObjectURL(decodeFileToBlob(imageData.value, partData.mimeType))}/>
                break
            case "application/pdf":
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                let pdfData = attachments.find(a => a.attachmentId === partData.body.attachmentId)
                if (!pdfData) setAttachments([...attachments, {
                    attachmentId: partData.body.attachmentId,
                    value: null,
                    mimeType: partData.mimeType
                }])
                if (pdfData?.value) htmlData = <Modal>
                    <iframe style={{width: '80vw', height: '90vh'}}
                            src={URL.createObjectURL(decodeFileToBlob(pdfData.value, "application/pdf"))}></iframe>
                </Modal>
                break
            default:
                console.log(partData)
                htmlData = `The "${partData.mimeType}" mimetype preview not yet available`
                break
        }
        return htmlData
        /*
        <div key={`${partData.mimeType}-${partData.id}`}>
        <strong> {partData.mimeType}</strong><br/>
        {htmlData}
    </div>*/
    }
    const replyToMail = () => {
        let structuredReply = `To: ${sender}
From: ${recipient}
Subject: Re: ${subject.replaceAll('Re:', '').trim()}
In-Reply-To: ${messageId}
References: ${messageId}
Content-Type: text/html; charset="UTF-8"


<p style="white-space: pre-wrap;">
${reply.replaceAll('\n', '\r\n')}
</p>


<blockquote>
On ${new Date().toDateString()} at ${new Date().toLocaleTimeString()}, <a href="mailto:${sender}">&lt;${sender}&gt;</a> wrote:

${renderToString(emailData?.parts?.map((pt) => renderParts(pt, true)))}
</blockquote>
`
        console.log(structuredReply)
        sendGmailReply(encodeToBase64(structuredReply), threadId, recipient, _id, () => {
            setDone()
            setReply('')
        })


    }
    return <div className="emailItem">
        <div className={'itemHeading'}>
            <a href={`https://mail.google.com/mail/u/0/#inbox/${id}`} style={{color: "cyan"}} target={'_blank'}>
                📧 {labels.map(l => `${l.emoji}${l.labelText}`).join('; ')}
            </a>
            <span className={"username"}>  {subject} </span>
            <small> {snippet}</small>
            <small className={'timeSince'}>from <strong>{sender}</strong> {timeSince(timestamp)} ago</small>
        </div>
        {expanded && <div className={'itemContent'} style={{background: 'lightgray', color: 'black'}}>
            {!emailData && <Loader/>}
            {emailData?.parts?.map((pt) => renderParts(pt, false))}
        </div>}

        {expanded && <div className={'actions'}>
            <div className={`socBoxBtn ${hideConvo ? '' : "active"}`}
                 onClick={() => setHideConvo(!hideConvo)}>{hideConvo ? "Show past conversation" : "Hide past conversation"}</div>
            <textarea rows={10} style={{width: '80%'}} value={reply} onChange={(e) => setReply(e.target.value)}>

            </textarea>
            <br/>
            <button className={"socBoxBtn"} onClick={replyToMail}>Send reply</button>
            <br/><br/>
            <small>DEBUG: ThreadID: {threadId} || ID: {id} || messageId: {messageId}</small>
        </div>}
    </div>
};
export default EmailItem;
