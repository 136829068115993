import {timeSince, wait} from "../../../../utils/utils";
import {useEffect, useState} from "react";
import axios from "../../../../config/axiosConfig";
import Loader from "../../loader/Loader";
import {useGraphContext} from "../../../../context/graphContext/context";

const ChatItem = ({expanded, timestamp, platform, participants, unread_count, pageId,id}) => {

    const [lastLoad, setLastLoad] = useState(0);
    const [messages, setMessages] = useState([]);
    const [msgLoad, setMsgLoad] = useState(false)
    const [reply, setReply] = useState('')
    const {replyToChat,uploadFile} = useGraphContext()

    useEffect(() => {
        const getChatMessages = async () => {
            let [resp, blank] = await Promise.all([axios.get(`/getChatMessages?chatId=${id}`), wait(0.5)])
            setMessages(resp.data)
        }
        if (expanded && lastLoad + 15 * 60 * 1000 < Date.now()) {

            getChatMessages()
            setLastLoad(Date.now())
        }
    }, [expanded])
    const replyToChatFunc = async () => {
        if (reply) {
            let cache = reply
            setReply('')
            setMsgLoad(true)
            let resp = await replyToChat(participants[0]?.id, reply, platform)
            setMessages([resp, ...messages])
            setMsgLoad(false)
        }
    }

    const uploadFileFunc = async (e) => {
        if (e.target.files[0].size > 24 * 1024 * 1024) {
            alert("File is too big!");
            return e.target.value = ''
        }
        setMsgLoad(true)
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append('pageId', pageId)
        formData.append('recipient', participants[0].id)
        formData.append('platform', platform)
        try {
            let resp =await uploadFile(formData)
            console.log(resp)
            setMessages([resp, ...messages])
        } catch (e) {

        }
        e.target.value = ''
        setMsgLoad(false)
    }

    const renderAttachment = (attachment) => {
        if (attachment.image_data) {
            let img = attachment.image_data
            return <a key={attachment.id} href={img.url} target={"_blank"}> <img src={img.preview_url}
                                                                                 style={{height: 150, width: "auto"}}/></a>

        }
        if (attachment.video_data) {
            let vid = attachment.video_data
            return <video controls key={attachment.id} src={vid.url} height={250}/>
        }
        if (attachment.mime_type?.includes('audio')) {
            return <audio key={attachment.id} controls src={attachment.file_url}>
                <a href={attachment.file_url} download={attachment.name}> Download audio </a>
            </audio>
        }
        if (attachment.file_url) {
            return <span key={attachment.id}> <strong>File uploaded:</strong> <>{attachment.name}</> <a
                href={attachment.file_url} download={attachment.name} style={{color: "lightgreen"}}>🗎</a> Click the green icon to download</span>
        }
    }
    if (msgLoad) return <Loader/>
    return <div className="chatItem">
        <div className={'itemHeading'}>
            <a href={'#'} target={'_blank'}>
                <img src={'/msg-logo.png'} className={'logo'}/>
                {platform === 'fb' && <img src={'/fb-logo.png'} className={'logo'}/>}
                {platform === 'ig' && <img src={'/ig-logo.png'} className={'logo'}/>}
            </a>
            <span className={"username"}> {participants.map(p => p.username || p.name).join('; ')} {unread_count > 0 && <span>({unread_count})</span>} </span>
            <small className={'timeSince'}>{timeSince(timestamp)} ago</small>
        </div>
        {expanded && <div className={'actions'}><textarea placeholder={'reply to chat'}
                                                          onChange={e => setReply(e.target.value)}
                                                          value={reply}></textarea>
            <button onClick={replyToChatFunc}>Send</button>
            <br/>
            <input type={"file"} max onChange={uploadFileFunc}></input>
        </div>}
        {expanded && <div className={'itemContent'}>
            {messages.length === 0 && <Loader/>}
            {messages.map(msg => {
                return (<div key={msg._id} className={'msgItem'}>
                    <div><strong>{msg.sender}:</strong><small
                        style={{float: "right"}}>{timeSince(msg.timestamp)}</small></div>
                    <span>{msg.message}</span>
                    <span>{msg.attachments?.map(renderAttachment)}</span>
                </div>)
            })}
        </div>}
    </div>
};
export default ChatItem;
