export const GET_USER_LIST_BEGIN = "GET_USER_LIST_BEGIN"
export const STORE_USERS_LIST = "STORE_USERS_LIST";
export const GET_USERS_LIST_ERROR = "GET_USERS_LIST_ERROR";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";
export const UPDATE_USER_BEGIN = "UPDATE_USER_BEGIN";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";
export const CREATE_USER_BEGIN = "CREATE_USER_BEGIN";
export const TOGGLE_GRAPH_ASSIGNED_BEGIN = "TOGGLE_GRAPH_ASSIGNED_BEGIN";
export const TOGGLE_GRAPH_ASSIGNED_ERROR = "TOGGLE_GRAPH_ASSIGNED_ERROR";
export const STORE_LABELS_LIST = "STORE_LABELS_LIST"

export const GET_LABELS_LIST_ERROR = "GET_LABELS_LIST_ERROR"

export const UPDATE_LABEL_BEGIN = "UPDATE_LABEL_BEGIN"