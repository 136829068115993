import "./InboxPage.css";
import Loader from "../../partials/loader/Loader";
import {useGraphContext} from "../../../context/graphContext/context";
import {useEffect, useState} from "react";
import {timeSince} from '../../../utils/utils'
import InboxItem from "../../partials/inboxItem/InboxItem";
import {useUserContext} from "../../../context/userContext/context";
import {useAdminContext} from "../../../context/adminContext/context";
import InboxFilters from "../../partials/inboxFilterSelect/InboxFilterSelect";
import ActivePageActions from "../../partials/ActivePageActions/ActivePageActions";
import {useEmailContext} from "../../../context/emailContext/context";

const InboxPage = () => {
    const {graphPages, graphLoading, pageInbox, fetchPagesList, getPageInbox, readAllNotif} = useGraphContext();
    const {emailLoading, emailInbox, recipients, getMailRecipients, getRecipientMessages} = useEmailContext()
    const {user} = useUserContext()
    const {getUsers, users, updateUser, labels, getLabels} = useAdminContext()
    const [activePage, setActivePage] = useState('')
    const [activeRecipient, setActiveRecipient] = useState('')
    const [chatsActive, setChatsActive] = useState(true)
    const [fbCommentsActive, setFbCommentsActive] = useState(true)
    const [igCommentsActive, setIgCommentsActive] = useState(true)
    const [allRead, setAllRead] = useState(false)

    const [filters, setFilters] = useState([])

    useEffect(() => {
        fetchPagesList()
        getLabels()
        getMailRecipients()
    }, [])
    useEffect(() => {
        if (!users.length && user?.accessLevel >= 10) getUsers()
    }, [user])

    useEffect(() => {
        if (activePage) {
            getPageInbox(activePage)
        }
        if (activeRecipient) {
            getRecipientMessages(activeRecipient)
        }
        setAllRead(false)

    }, [activePage, activeRecipient])


    let mixedInbox = []
    pageInbox.chats?.forEach(c => {
        if (activePage)  mixedInbox.push({type: 'chat', ...c})
    })
    pageInbox.fbComments?.forEach(c => {
        if (activePage)  mixedInbox.push({type: "fbc", ...c})
    })
    pageInbox.igComments?.forEach(c => {
       if (activePage) mixedInbox.push({type: 'igc', ...c})
    })
    emailInbox.messages?.forEach(c => {
        if (filters.every(f => f.length === 0) || filters?.every(fg => fg.length === 0 || fg?.some(fl => c.labels.map(a => a.labelText).includes(fl)))) mixedInbox.push({type: 'mail', ...c})
        // filters?.every(fg => fg.some(fl => c.labels?.map(a => a.labelText).includes(fl)))
    })

    let filteredGraphPages = graphPages.map(gp => {
        let mixedLabels = labels.filter(mlb => mlb.type === 'mixed' && mlb.labels.find(plb => gp.labels.map(a => a._id).indexOf(plb._id) !== -1))
        return {...gp, labels: [...gp.labels, ...mixedLabels]}
    })
    let activePageData = filteredGraphPages.find(gp => gp.id === activePage)
    let activeRecipientData = recipients.find(gp => gp.email === activeRecipient)
    if (!filters.every(f => f.length === 0)) filteredGraphPages = filteredGraphPages.filter(gp => filters?.every(fg => fg.length === 0 || fg?.some(fl => gp.labels.map(a => a.labelText).includes(fl))))


    console.log(activePage, activeRecipient)
    if ((graphPages.length === 0 && graphLoading)) return <Loader bigLoader={true}/>
    return <div className={'container'}>

        <div className={'pagesSidebar'}>
            <InboxFilters syncFilters={setFilters}/>
            {recipients.sort((k, l) => l.lastNotification - k.lastNotification).map(r => (
                <div key={r._id} className={`pageItem ${activeRecipient === r.email && 'active'}`}
                     onClick={() => {
                         setActivePage('')
                         setActiveRecipient(r.email)
                     }}>

                    📧 {r.email}
                    <div style={{textAlign: 'right'}}>
                        <strong className={'labelListPreview'}>{r.labels.map(lb => <span
                            title={lb.labelText}>{lb.emoji}</span>)}</strong>
                        <small>{r.notificationCount} new | Last {timeSince(r.lastNotification)} ago</small>
                    </div>
                </div>))}
            {filteredGraphPages.sort((k, l) => l.lastNotification - k.lastNotification).map(p => (
                <div key={p._id} className={`pageItem ${activePage === p.id && 'active'}`}
                     onClick={() => {
                         setActiveRecipient('')
                         setActivePage(p.id)
                     }}>
                    <a href={`https://facebook.com/${p.id}`} target={"_blank"}>
                        <img src={'/fb-logo.png'} className={'logo'}/>
                    </a>
                    {p.instagramId &&
                        <a href={`https://instagram.com/${p.username}`} target={"_blank"}>
                            <img src={'/ig-logo.png'} className={'logo'}/>
                        </a>}
                    {p.name}
                    <div style={{textAlign: 'right'}}>
                        <strong className={'labelListPreview'}>{p.labels.map(lb => <span
                            title={lb.labelText}>{lb.emoji}</span>)}</strong>
                        <small>{p.notificationCount} new | Last {timeSince(p.lastNotification)} ago</small>
                    </div>
                </div>))}
        </div>
        <div className={'inboxList'}>
            {(activePage) &&
                <ActivePageActions
                    chatsActive={chatsActive}
                    setChatsActive={setChatsActive}
                    fbCommentsActive={fbCommentsActive}
                    setFbCommentsActive={setFbCommentsActive}
                    igCommentsActive={igCommentsActive}
                    setIgCommentsActive={setIgCommentsActive}
                    readAllFunc={() => {
                        readAllNotif(activePage || activeRecipient)
                        setAllRead(true)
                    }}
                    activePageData={activePageData}
                    activePage={activePage}
                    activeRecipientData={activeRecipientData}
                    activeRecipient={activeRecipient}


                />}

            {graphLoading || emailLoading && <Loader bigLoader={true}/>}
            {!graphLoading && mixedInbox.sort((a, b) => b.timestamp - a.timestamp).filter(a => {
                return activeRecipient || (fbCommentsActive && a.type === 'fbc') || (chatsActive && a.type === 'chat') || (igCommentsActive && a.type === 'igc')
            }).map(inb => <InboxItem allRead={allRead} key={inb.id} {...inb}/>)}
        </div>
    </div>;
};
export default InboxPage;
